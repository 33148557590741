
export const ES = 
{  
    Home:{
        Header:{
            n01:'Obtén selecciones deportivas generadas por IA todos los días que superan la precisión de los handicappers convencionales. ¡Aprovecha la ventaja!',
            n02:'Picks Intelligence Pro (PIP), un sistema diseñado para una precisión suprema en cada deporte',
            n03:'',
            n04:'',
            n05:'',
            },
            Section02:{
            n01:'Los consejos de apuestas deportivas de IA son la opción más nueva y precisa en la industria de las apuestas deportivas.',
            n02:'Pico analiza millones de datos e información deportiva cada segundo, cada hora, día y noche, para brindarte solo las mejores selecciones disponibles.',
            n03:'',
            n04:'',
            n05:'',
            },
            Section03:{
            n01:'Un mínimo de 3 selecciones generadas por IA directamente a tu teléfono celular. Pruébelo gratis o obtenga nuestra membresía semanal por $19,99 o nuestra membresía mensual por solo $49,99.',
            n02:'PICO analiza millones de puntos de datos en cada juego de la NFL, NBA, NHL, MLB, UFC, boxeo y tenis.',
            n03:'',
            n04:'',
            n05:'',
            },
            Section04:{
            n01:'PICO publica un mínimo de 3 selecciones deportivas todos los días. Estas son las mejores opciones disponibles según el análisis de algoritmos.',
            n02:'TESTIMONIOS',
            n03:'Tasa de acierto de las selecciones de Pico AI: 69% o más',
            q01:'¡No hay mejor opción, Pico AI es mucho más preciso que cualquier handicapper, con diferencia!',
            r01:'-Frank Maggi',
            q02:'¡Me siento como el Michael Jordan de las Apuestas Deportivas ahora gracias a Pico AI!',
            r02:'-Paul Darwin',
            q03:'¡La mejor manera de vencer al sistema!',
            r03:'-David Smith',
            q04:'¡Desde que dejé mis selecciones emocionales en casa y comencé a usar las Selecciones de Pico AI, finalmente estoy ganando dinero!',
            r04:'-Armund Fritz',
            q05:'¡Pico AI es sin duda el enemigo número uno de las casas de apuestas!',
            r05:'-John Villamonte',
            q06:'¡Más del 70% de precisión es algo que nunca antes había experimentado, este sistema realmente funciona!',
            r06:'-Peter Diaz',
            q07:'Al principio no confiaba en ello, pero ahora no puedo vivir sin las selecciones de Pico AI directamente en mi teléfono celular los 7 días de la semana. ¡Funciona!',
            r07:'-Layla Noguera',
            },
            Section05:{
            n01:'Conviértete en miembro de Pico AI Pics y recibe un mínimo de 3 selecciones diarias, 90 selecciones al mes. Directamente a tu teléfono celular en nuestro canal privado de telegrama. Pruébelo gratis o obtenga nuestra membresía semanal por $19,99 o nuestra membresía mensual por solo $49,99 a 0.555 centavos por selección. ¿No tienes Telegram aún? Por favor, descárgalo ahora, es fácil y conveniente.',
            n02:'¿No estás satisfecho? Cancela en cualquier momento haciendo clic en el botón de cancelar suscripción.',
            n03:'Cancelar suscripción',
            n04:'',
            n05:'',
            },
        Section06:{
            n01:'Descarga Telegram gratis para formar parte de nuestra comunidad deportiva y empezar a ganar constantemente.',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
      
    },
    Pricing:{
        Header:{
            n01:'PICO AI Precio',
            n02:'Precio',
            n03:'',
            n04:'',
            n05:'',
        },
        Section02:{
            n01:'Acceso a todas las selecciones',
            r01:'Un plan de suscripción simple para TODAS nuestras selecciones deportivas.',
            n02:'Alertas de apuestas de emergencia',
            r02:'Oportunidades doradas de último minuto',
            n03:'Cubriendo 7 deportes',
            r03:'NFL, NBA, NHL, MLB, UFC, boxeo y tenis.',
            n04:'3 Selecciones todos los días',
            r04:'Tasa de éxito del 89%',
        },
        Section03:{
            n01:'$49.99',
            n02:'90 AiPicks ',
            n03:'NFL, NBA, NHL, MLB, UFC,',
            n04:'Boxeo y Tenis.',
            n05:'Canal privado de Telegram',
            n06:'Mensual',
            n07:'Suscripción',
            n08:'$49.99 membresia mensual',
            n09:'$19.99 membresia semanal',
            n10:'Prueba Gratis por 3 días.'
        },
        Section04:{
            n01:'3 Selecciones deportivas diarias generadas por IA',
            n02:'Entregadas directamente en tu',
            n03:'Teléfono celular',
            n04:'¿No estás satisfecho? Cancela en cualquier momento.',
            n05:'',
        },  
        Section05:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section06:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
      
    },
    Support:{
        Header:{
            n01:'Soporte de PICO AI',
            n02:'Soporte de Pico AI Picks',
            n03:'Ponte en contacto',
            n04:'Ponte en contacto con nosotros',
            n05:'Enviar Mensaje',
        },
        Section02:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section03:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section04:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },  
        Section05:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section06:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
      
    },
    About:{
        Header:{
            n01:'Acerca de Pico Ai Picks',
            n02:`Pico AI Picks es la idea de un entusiasta de los deportes y las apuestas panameño con más de 20 años de experiencia. Después de probar todos los métodos convencionales, decide buscar ayuda de la Inteligencia Artificial. Su razonamiento es que nada se compara con la precisión de la IA. Nunca apuestes basándote en emociones, deja que PICO AI haga la investigación por ti y ¡aprovecha la ventaja!`,
            n03:`¿Quién es Pico Ai?`,
            n04:'',
            n05:'',
        },
        Section02:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section03:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section04:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },  
        Section05:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section06:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
      
    },
    Extra:{
        Header:{
            n01:'INGLES',
            n02:'ESPAÑOL',
            n03:'Subscribir',
            n04:'Inicio',
            n05:'Precio',
            n06:'Soporte',
            n07:'Sobre Pico',
            n08:'Preguntas frecuentes',
            n09:'Cargando',
            q1: "¿Quién es Pico Ai?",
            r1: `Pico AI Picks es la idea de un entusiasta panameño de los deportes y las apuestas con más de 20 años de experiencia. Después de probar todos los métodos convencionales, decide buscar ayuda de la Inteligencia Artificial. Su razonamiento es que nada se compara con la precisión de la IA. Nunca apuestes basándote en emociones, deja que PICO AI haga la investigación por ti y aprovecha la ventaja.`,
            q2: "¿Las selecciones están garantizadas?",
            r2: `No, nada está garantizado al 100%. Pico AI Picks te proporcionará las mejores y más probables selecciones disponibles basadas en nuestros algoritmos.`,
            q3: "¿Debería tomar todas las selecciones?",
            r3: `Depende de ti. Puedes tomar las que prefieras. Las selecciones no están destinadas a ser combinadas, siempre recomendamos solo apuestas directas o sencillas.`,
            q4: "¿Puedo realizar las apuestas en su sitio?",
            r4: `No, pero puedes obtener las predicciones más precisas.`,
            q5: "¿Cuándo recibiré mis selecciones?",
            r5: `Comienzas a recibir las selecciones el día en que te suscribes.`,
            q6: "¿Cómo cancelo mi suscripción?",
            r6: `Simplemente haciendo clic en el botón de cancelar suscripción.`,
        },
        Section02:{
            n01:'Cliente sin suscripciones activas',
            n02:'Cliente no encontrado!',
            n03:'Por favor, seleccione una suscripción para cancelar.',
            n04:'Suscripción cancelada exitosamente.',
            n05:'Cancele su suscripción a través de la pasarela de pago Stripe',
        },
        Section03:{
            n01:'Escriba el correo electrónico donde tiene su suscripción activa.',
            n02:'Cerrar ventana',
            n03:'Cargando...',
            n04:'Buscar suscripciones',
            n05:'Suscripciones',
        },
        Section04:{
            n01:'Seleccione su suscripción activa.',
            n02:'Cancelando...',
            n03:'Cancelar suscripción',
            n04:'Cliente encontrado y suscripciones obtenidas.',
            n05:'',
        },
        Section05:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section06:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
      
    },
    Footer:{
        Header:{
            n01:'Obtén Selecciones Deportivas Generadas por IA que Destruyen a los Handicappers Convencionales',
            n02:'Inicio',
            n03:'Precios',
            n04:'Soporte',
            n05:'Acerca de',
            n06:'Derechos de autor © 2024. Todos los derechos reservados PicoAiPicks',
            
        },
        
    }, 
    type:"ES",
}

export const EN = 
    {    
    Home:{
        Header:{
            n01:'Get AI Generated Sports picks every day that go beyond the accuracy of conventional handicappers. Seize the upper hand!',
            n02:'Picks Intelligence Pro (PIP) a system designed for supreme accuracy in every sport',
            n03:'',
            n04:'',
            n05:'',
        },
        Section02:{
            n01:'Ai Sports Betting tips are the most accurate choice in the industry of sports betting.',
            n02:'Pico Analyzes millions of sport data and information every second of every hour, day and night, in order to give you only the best possible picks available',
            n03:'',
            n04:'',
            n05:'',
        },
        Section03:{
            n01:'A minimum of 3 AI generated picks directly to your cell phone. Try it free or take our weekly membership for $19.99 or our monthly membership for just $49.99..',
            n02:'PICO crunches millions of data points on every game in NFL, NBA, NHL, MLB, UFC,Boxing and Tennis.',
            n03:'',
            n04:'',
            n05:'',
        },
        Section04:{
            n01:'PICO Releases a minimum of 3 sports picks every day. These are the best options available according to the algorithms analysis.',
            n02:'TESTIMONIALS',
            n03:'Pico Ai Picks winning rate 69% and up',
            q01:'There is no better choice, Pico Ai is way more accurate than any handicapper by far!',
            r01:'-Frank Maggi',
            q02:'I feel like the Michael Jordan of Sports Betting now thanks to Pico AI',
            r02:'-Paul Darwin',
            q03:'Best way to beat the system!',
            r03:'-David Smith',
            q04:'Since I left my emotional picks at home and started using Pico Ai Picks Im finally making money! ',
            r04:'-Armund Fritz',
            q05:'Pico AI is for sure the bookies number one enemy!',
            r05:'-John Villamonte',
            q06:'Over 70% accuracy is something I’ve never experience before, this system does work!',
            r06:'-Peter Diaz',
            q07:'I did not trust it at the beginning but now I cant live without Pico AI picks straight to my cell phone 7 days a week. It works!',
            r07:'-Layla Noguera',
        },  
        Section05:{
            n01:'Become a member of Pico AI Pics and receive a minimum of 3 picks daily, 90 picks a month. Direct to your cell phone in our private telegram channel. Try it free or take our weekly membership for $19.99 or our monthly membership for just $49.99. / 0.555 cents per pick. Dont have telegram yet? Pls download it now, its easy and convenient.',
            n02:'Not happy? Cancel anytime by clicking the unsubscribe button.',
            n03:'unsubscribe',
            n04:'',
            n05:'',
        },
        Section06:{
            n01:'Please download telegram for free to become a part of our sport  community and start winning consistently.',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
      
    },
    Pricing:{
        Header:{
            n01:'PICO AI Pricing',
            n02:'Pricing',
            n03:'',
            n04:'',
            n05:'',
        },
        Section02:{
            n01:'Access to all picks',
            r01:'One simple subscription plan for ALL our sports picks.',
            n02:'Emergency bet alerts',
            r02:'Last minute golden opportunities',
            n03:'Covering 7 sports',
            r03:'NFL,NBA, NHL, MLB,UFC,Boxing and Tennis.',
            n04:'3 Picks Every Days',
            r04:'89% Win rate',
        },
        Section03:{
            n01:'$49.99',
            n02:'90 AiPicks',
            n03:'NFL, NBA, NHL, MLB, UFC,',
            n04:'Boxing and Tennis.',
            n05:'Private Telegram Channel',
            n06:'Monthly',
            n07:'Subscription',
            n08:'$49.99 monthly membership',
            n09:'$19.99 weekly membership',
            n10:'Or try it for free for 3 days.'
        },
        Section04:{
            n01:'3 Daily AI sports picks',
            n02:'Delivered directly on your',
            n03:'CellPhone',
            n04:'Not Happy? Cancel anytime.',
            n05:'',
        },  
        Section05:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section06:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
      
    },
    Support:{
        Header:{
            n01:'PICO AI Support',
            n02:'Support Pico Ai Picks',
            n03:'Get in Touch',
            n04:'Get in touch with us',
            n05:'Send Massage',
        },
        Section02:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section03:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section04:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },  
        Section05:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section06:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
      
    },
    About:{
        Header:{
            n01:'About Pico Ai Picks',
            n02:`Pico AI Picks is the idea of a Panamanian sports 
            and betting enthusiast for over 20 years. After trying 
            all conventional methods decides to get help from Artificial 
            Intelligence. His reasoning is that nothing compares to the 
            accuracy of AI. Never bet based on emotions, let PICO AI  
            do the research for you and seize  the upper hand!`,
            n03:`Who's is Pico Ai ?`,
            n04:'',
            n05:'',
        },
        Section02:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section03:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section04:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },  
        Section05:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section06:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
      
    },
    Extra:{
        Header:{
            n01:'ENGLISH',
            n02:'SPANISH',
            n03:'Subcribe',
            n04:'Home',
            n05:'Pricing',
            n06:'Support',
            n07:'About',
            n08:'Frequently asked questions',
            n09:'Loading',
            q1: "Who's is Pico Ai ?",
            r1: `Pico AI Picks is the idea of a Panamanian sports and betting enthusiast for over 20 years. After trying all conventional methods decides to get help from Artificial Intelligence. His reasoning is that nothing compares to the accuracy of AI. Never bet based on emotions, let PICO AI do the research for you and seize  the upper hand!`,
            q2: "Are picks guaranteed?",
            r2: `No, nothing  is 100%  guaranteed. Pico AI Picks will give you the best and most  probable picks available based on our Algorithms. `,
            q3: "Should I take all the picks?",
            r3: `Its up to you. You can take the ones you like. Picks are not meant to be parlayed, we always  recommend straight or direct bets only.`,
            q4: "Can I place the bets on your site?",
            r4: `No, but you can get the most accurate predictions.`,
            q5: "When will I get my picks?",
            r5: `You start getting picks the day you subscribe. `,
            q6: "How do I cancel my subscription?",
            r6: `Just by clicking on the unsubscribe button.`,
        },
        Section02:{
            n01:'Client without active subscriptions',
            n02:'Client not found!',
            n03:'Please select a subscription to cancel.',
            n04:'Subscription successfully canceled.',
            n05:'Cancel your subscription through the Stripe payment gateway',
        },
        Section03:{
            n01:'Enter the email address where you have your active subscription.',
            n02:'Close window',
            n03:'Loading...',
            n04:'Search subscriptions',
            n05:'Subscriptions',
        },
        Section04:{
            n01:'Select your active subscription.',
            n02:'Canceling...',
            n03:'Cancel subscription',
            n04:'Client found and subscriptions retrieved.',
            n05:'',
        },
        Section05:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
        Section06:{
            n01:'',
            n02:'',
            n03:'',
            n04:'',
            n05:'',
        },
      
    },
    Footer:{
        Header:{
            n01:'Get AI Generated Sports Picks that Destroy Conventional Handicappers',
            n02:'Home',
            n03:'Pricing',
            n04:'Support',
            n05:'About',
            n06:'Copyright &copy; 2024. All Rights Reserved PicoAiPicks',
            
            
        },
        
    },            
    type:"EN",
}