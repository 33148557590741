import React, { useState } from "react";
import useLang from "../../hooks/useLang";
import logoImage from "../../assets/img/logo/StripeL.svg";


const WinCancel = () => {
    const { unsubwin, ToggleUnSub } = useLang();
    const [email, setEmail] = useState("");
    const [singularsub, setSingularSub] = useState("");
    const [subscriptions, setSubscriptions] = useState([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [step, setStep] = useState(1);
    const {lang} = useLang()

    const resetStep = () => {
        setStep(1)
        setMessage("")
        setSubscriptions([]);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        setSubscriptions([]);
        setLoading(true);

        try {
            const response = await fetch("https://api.picoaipicks.com/services/find-min.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Error desconocido");
            }

            const data = await response.json();
            setSubscriptions(data.customer.active_subscriptions); 
            // if (data.subscriptions && data.subscriptions.length > 0) {
            //     setSubscriptions(data.subscriptions); // Supongo que la API devuelve un arreglo de suscripciones
               
            //     setMessage("Seleccione una suscripción para cancelar.");
            // } else {
            //     setMessage("No se encontraron suscripciones activas para este correo.");
            // }
            // setMessage(data.message);
            setMessage(lang.data.Extra.Section04.n04)

        
         

           if(data.customer.active_subscriptions.length === 0 ) {
            setStep(1)
            setMessage(`${lang.data.Extra.Section02.n01}`);
          } else {
            setStep(2)
         }
        } catch (error) {
            setMessage(`${lang.data.Extra.Section02.n02}`);
        } finally {
            setLoading(false);
        }
    };

    

    const handleCancel = async e => {
        e.preventDefault();
        if (!singularsub) {
            setMessage(`${lang.data.Extra.Section02.n03}`);
            return;
        }

        setLoading(true);
        setMessage("");

        try {
            const response = await fetch("https://api.picoaipicks.com/services/cancel.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ subscription_id: singularsub }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Error desconocido al cancelar la suscripción.");
            }

            // const data = await response.json();
            setMessage(`${lang.data.Extra.Section02.n04}`);
            setSubscriptions([]);
            setSelectedSubscription(null);
            setStep(1)
            setEmail("")
        } catch (error) {
            setMessage(error.message);
        } finally {
            setLoading(false);
        }
    };
   

    return (
        <>
            {unsubwin === true && (
                <section className="Win-Cancel">
                   
                    <section className="Win-Cancel-for">
                    <div className="bar-bar-progg">
                        <div className={ step === 1 ? 'active-bar-bar-bar-prog':''}
                            onClick={resetStep}
                        >

                        </div>
                        <div className={ step === 2 ? 'active-bar-bar-bar-prog':''}>
                            
                        </div>

                    </div>

                    { step === 1 ?

                            <>
                             <img className="logostripe" src={logoImage} alt="" />
                        <h2 className="text-center text-disc">
                        {lang.data.Extra.Section02.n05}
                        </h2>
                        <p className="text-center text-disc text-disc-font">
                        {lang.data.Extra.Section03.n01}
                        </p>
                        <form className="formium" onSubmit={handleSubmit}>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <section className="cancelar-cancel" onClick={ToggleUnSub}>
                            {lang.data.Extra.Section03.n02}
                            </section>
                            {message && <p className="response-message text-center">{message}</p>}
                            <button type="submit" className="btn btn-two" disabled={loading}>
                                {loading ? `${lang.data.Extra.Section03.n03}` : `${lang.data.Extra.Section03.n04}`}
                            </button>
                        </form>

                            </> : null

                    }

                    { step === 2 ?

                    <>
                    <img className="logostripe" src={logoImage} alt="" />
                    <h2 className="text-center text-disc">
                    {lang.data.Extra.Section03.n05}
                    </h2>
                    <p className="text-center text-disc text-disc-font">
                    {/* seleccione su suscripción activa. */}
                    {lang.data.Extra.Section04.n01}
                    </p>
                    <form className="formium" >
                    {subscriptions.map((sub) => (
                        <div key={sub.subscription_id}>
                            <label>
                                <input
                                    type="radio"
                                    name="singularsub"
                                    value={singularsub}
                                   onChange={(e) =>  setSingularSub(sub.subscription_id)   }

                                />
                                {sub.subscription_id} - ${sub.amount} / {sub.status}
                            </label>
                        </div>
                    ))
                    }
                    <section className="cancelar-cancel" onClick={ToggleUnSub}>
                        {/* Cerrar ventana */}
                        {lang.data.Extra.Section03.n02}
                    </section>
                    {message && <p className="response-message text-center">{message}</p>}
                    <button
                        className="btn btn-two"
                        onClick={handleCancel}
                        disabled={loading}
                    >
                        {loading ? `${lang.data.Extra.Section04.n02}` : `${lang.data.Extra.Section04.n03}`}
                    </button>
                    </form>
                    </> : null
                    } 
                    </section>


                </section>
            )}
        </>
    );
};

export default WinCancel;
